/* src/styles/Footer.css */

.app-footer {
  background: #fff;
  display: flex;
  padding: 20px 28px;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  color: #000;
  margin: 0 10px;
}

.footer-nav a {
  color: #000;
  text-decoration: none;
}

.footer-nav a:hover {
  text-decoration: underline;
}
