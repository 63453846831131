body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'FoundersGroteskBold';
  font-weight: 700;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-Bold.otf);
}


@font-face {
  font-family: 'FoundersGroteskBold-italic';
  font-weight: 700;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-BoldItalic.otf);
}

@font-face {
  font-family: 'FoundersGrotesk-semibold';
  font-weight: 600;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-Semibold.otf);
}

@font-face {
  font-family: 'FoundersGrotesk-semibold';
  font-weight: 600;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-SemiboldItalic.otf);
}

@font-face {
  font-family: 'FoundersGrotesk-light';
  font-weight: 400;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-Light.otf);
}


@font-face {
  font-family: 'FoundersGrotesk-medium';
  font-weight: 500;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-Medium.otf);
}

@font-face {
  font-family: 'FoundersGrotesk-medium-italic';
  font-weight: 500;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-MediumItalic.otf);
}

@font-face {
  font-family: 'FoundersGroteskRegular';
  font-weight: 400;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-Regular.otf);
}


ul.list-tick {
  list-style: none;
  /* position: relative; */
  /* padding-left: 50px; */
}

ul.list-tick li:before {
  content: '✓';
  left: 0px;
  margin-right: 10px;
  /* width: 100px; */
  /* position: absolute; */
  color: greenyellow;
  /* right: 40px; */
}