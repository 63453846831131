.sign-up-form-container {
  width: 722px;
  /* height: 415px; */
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(133, 133, 133, 0.25);
  /* padding: 55px 76px 53px 78px; */
  padding: 40px;
  /* margin-bottom: 83px; */
  margin-bottom: 40px;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  /* height: 100%; */
  width: 100%;
}

.form-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.form-label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.form-input {
  display: flex;
  width: 100%;
  padding: 15px 25px;
  align-items: center;
  border-radius: 9px;
  background: #eaeaea;
  border: transparent;
}

.form-submit {
  display: flex;
  width: 100%;
  padding: 14px 51px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #5e59ff;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
}

.form-submit-google {
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  /* background: #5e59ff; */
  color: #346ef1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #346ef1;
}

.form-footer {
  width: 100%;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-footer a {
  color: #070707;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.form-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-error {
  color: #ff4057;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-success {
  color: #346ef1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-title {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.form-description {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.px3-gap {
  gap: 3px !important
}

.px13-gap {
  gap: 13px !important
}