.carousel-container {
  width: 100%; /* Adjust the width as needed */
  margin: auto;
}

.slick-initialized .slick-slide {
  height: 250px;
}

.slick-dots {
  list-style: none;
  display: flex !important;
  justify-content: center;
  /* margin-top: 25px; */
  position: relative;
}

.slick-dots li {
  margin: 0 5px;
  height: 16px;
  width: 16px;
}

.slick-dots li button {
  font-size: 10px; /* Adjust the font size as needed */

  background: transparent; /* Adjust the indicator color */
  border: none;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  height: 16px;
  width: 16px;
  border: #000 solid 2px;
}

.slick-dots li button:before {
  content: none !important;
}

.slick-dots li.slick-active button {
  background: #000; /* Adjust the active indicator color */
  opacity: 1;
  color: #000;
}

.carousel-item {
  min-height: 250px;
  width: 526px;
  border-radius: 12px;
  background: rgba(217, 217, 217, 0.3);
  padding: 28px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  height: 100% !important;
  flex-grow: 1;
}

.review {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 0%; /* 0px */
}

.rated-stars {
  margin-top: 24px;
  margin-bottom: 24px;
}
