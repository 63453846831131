.popup-background {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #1919198C;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(4px);
  animation-duration: .3s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fade-in-animation {
  0% {
    backdrop-filter: blur(0px);
    background-color: transparent;
  }

  100% {
    backdrop-filter: blur(4px);
    background-color: #1919198C;
  }
}

@keyframes fade-out-animation {
  0% {

    backdrop-filter: blur(4px);
    background-color: #1919198C;
  }

  100% {
    backdrop-filter: blur(0px);
    background-color: transparent;
  }
}

.content-area {
  /* height: 100%; */
  overflow: scroll;
  max-height: 90%;
  border-radius: 36px;
  width: fit-content;
  z-index: 10000;
  background-color: white;
  padding: 0px 84px;

  animation-duration: .3s;
  animation-delay: 0s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

}

@keyframes zoom-in-animation {
  0% {
    transform: scale(.3);
    opacity: .3;
  }

  100% {
    transform: translateY(1);
    opacity: 1;
  }
}

@keyframes zoom-out-animation {
  0% {
    transform: translateY(1);
    opacity: 1;
  }

  100% {
    transform: scale(.3);
    opacity: .3;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-title {
  font-size: 1.125rem;
  font-weight: 500;
}