.onboarding-page {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  overflow: scroll;
}

.content-container {
  display: flex;
  flex: 1;
  /* height: 95vh; */
  overflow: scroll;
  background-color: #F5F5F5;
  /* overflow-y: hidden; */
}

.left-half {
  flex: 1;
  background: var(--Gradient, linear-gradient(180deg, #5e59ff 7.67%, #322f95 85.56%));
  background-size: cover;
  padding: 50px 70px 50px 70px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.right-half {
  flex: 1;
  padding: 60px 100px;
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 36px;
  overflow: scroll;
  height: 100%;
}

.try-button-container {
  position: relative;
  width: 250px;
}

.try-free-button-bg {
  display: flex;
  width: 190px;
  height: 95px;
  position: absolute;
  top: -37px;
  left: -40px;
  z-index: 0;
}

.onboarding-try {
  color: var(--Dark-green, #57fda7);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.onboarding-hero-text {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
  z-index: 1;
}

.onboarding-sub-text {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 466px;
  margin-bottom: 30px;
}

.rating-container {
  display: flex;
  padding-left: 5px;
  gap: 18px;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 80px;
}

.profile-image {
  width: 58px;
  height: 58px;
  flex-shrink: 0;
  /* background: linear-gradient(0deg, rgba(235, 1, 255, 0.7), rgba(255, 40, 40, 0.7)); */
  /* border: 3px solid var(--Offwhite, #f5f5f5); */
  border-radius: 50%;
  margin-left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.onboarding-image {
  width: 100%;
  height: 100%;
}

.profile-container {
  display: flex;
}

.step-indicator {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* margin-bottom: 20px; */
}

.dot {
  width: 16px;
  height: 16px;
  background-color: white;
  /* Default dot color */
  border-radius: 50%;
  /* cursor: pointer; */
  transition: background-color 0.3s;
  border: #000;
  border-width: 2px;
  border-style: solid;
}

.dot.active {
  background-color: #000;
  /* Active dot color */
}

.line {
  flex: 1;
  height: 2px;
  background-color: #000;
  /* Line color */
  width: 70px;
  border: 2px solid #000;
}

.onbaording-form-title {
  /* color: #5E59FF; */
  /* font-size: 48px; */
  font-style: bold;
  font-weight: 700;
  font-family: FoundersGroteskBold;
  /* line-height: 48px; */
}

.select-wrapper::after {
  right: 10px;
}

.features-conatiner {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
}

.feature {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.feature-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.feature-icon {
  width: 30px;
  height: 30px;
}

.feature-title {
  color: var(--White, #fff);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 0.7;
}

.feature-description {
  color: var(--White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.skip-button {
  display: flex;
  padding: 4px 35px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  color: #a8a8a8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: white;
}

.company-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  width: 100%;
}

.company-form-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.company-form-options {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
}

.option-value {
  display: flex;
  padding: 14px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #d6d6d6;
  color: #0d0d0d;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.option-value.selected {
  background-color: #5e59ff;
  color: white;
  border: 2px solid #5e59ff;
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 14px 30px;
  cursor: pointer;
}

.custom-select svg {
  position: absolute;
  top: 50%;
  right: 30px;
  /* Adjust this value to control the distance from the right */
  transform: translateY(-50%);
  pointer-events: inherit;
  cursor: pointer;
}

@media (max-width: 768px) {
  .left-half {
    display: none;
  }

  .right-half {
    padding: 30px;
  }

  .option-value {
    padding: 8px 16px;
  }

  .form-footer {
    align-items: start;
  }

  .form-footer a {
    max-width: 150px;
  }
}


@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('/src/fonts/foundersgrotesk/FoundersGrotesk-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}



.founders-bold {
  font-family: 'FoundersGrotesk';
  font-weight: 700;

  vertical-align: middle;
}

.founders-light {
  font-family: 'FoundersGrotesk';
  font-weight: 300;

  vertical-align: middle;
}

.founders-regular {
  font-family: 'FoundersGrotesk';
  font-weight: 400;

  vertical-align: middle;
}

.founders-medium {
  font-family: 'FoundersGrotesk';
  font-weight: 500;

  vertical-align: middle;
}

.founders-semibold {
  font-family: 'FoundersGrotesk';
  font-weight: 600;

  vertical-align: middle;
}