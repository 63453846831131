/* SignUpLayout.css */

.sign-up-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  justify-content: center;
}

main {
  flex-grow: 1;
  display: flex;
  height: 100%;
  overflow: scroll;
  flex-direction: column;
  /* justify-content: space-between; */
}

.sign-up-gradient {
  width: 100%;
  height: 280px;
  position: absolute;
  top: 63px;
  z-index: -1;
  /* background-image: url("../../images/signup-layout-gradient.png"); */
  background: var(--Gradient, linear-gradient(180deg, #5e59ff 7.67%, #322f95 85.56%));
  background-size: cover;
}

.sign-up-layout-title {
  color: #000;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: 6px;
}
