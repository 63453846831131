/* src/styles/Header.css */

.chat-app-header {
  height: 63px;
  background: #fff;
  padding: 11px 20px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(211, 211, 211, 0.25) !important;
  z-index: 10;
}

.logo-image {
  width: 100px;
  height: 25px;
}

.login-button,
.login-button-desktop {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0d0d0d;
  text-align: right;
  /* font-family: Rethink Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chat-header-dropdown {
  border-color: #000 !important;
}

@media (max-width: 768px) {
  .login-button-desktop {
    display: none;
  }
  .login-button {
    display: flex;
  }
}

@media (min-width: 768px) {
  .login-button-desktop {
    display: flex;
  }
  .login-button {
    display: none;
  }
}
