.schedule-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.schedule-info {
  background: var(--Gradient, linear-gradient(180deg, #5e59ff 7.67%, #322f95 85.56%));
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule-info-conatiner {
  max-width: 1100px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
  text-align: center;
  /* padding-top: 80px; */
}

.schedule-title {
  color: #fff;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.schedule-description {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 950px;
}

.calendly-container {
  height: 100%;
  width: 100%;
}

.calendly-container button {
  display: flex;
  width: 567px;
  padding: 14px 51px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--Gradient, linear-gradient(180deg, #5e59ff 7.67%, #322f95 85.56%));

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
}

@media (max-width: 768px) {
  .schedule-title {
    font-size: 24px;
  }

  .schedule-description {
    max-width: 300px;
  }
}
