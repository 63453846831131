.chat-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* position: relative; */
  justify-content: center;
  align-items: center;
  background-color: white;
}

.chat-layout-body {
  flex-grow: 1;
  /* display: flex; */
  height: 100%;
  overflow: scroll;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
}