/* src/styles/Header.css */

.app-header {
  height: 63px;
  background: #fff;
  padding: 20px 45px 20px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-image {
  width: 100px;
  height: 25px;
}

.login-button,
.login-button-desktop {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0d0d0d;
  text-align: right;
  /* font-family: Rethink Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .login-button-desktop {
    display: none;
  }
  .login-button {
    display: flex;
  }
}

@media (min-width: 768px) {
  .login-button-desktop {
    display: flex;
  }
  .login-button {
    display: none;
  }
}
